

import React,{useState, useEffect} from 'react'; 
import { Button } from 'reactstrap';
import logo from './imgs/SunWestLogo.png';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,  
} from "react-router-dom";
import {Row,  Col,Form}from 'react-bootstrap';
import emailjs from 'emailjs-com';
import axios from "axios";


const  Contact =() =>{
  const [propAddressState, setPropAddressState] = useState("");
  const [statesOptions, setStatesOptions] = useState("");
  const [clientIP, setClientIP] = useState("");

  function sendEmail(e){
    e.preventDefault();
    emailjs.sendForm(
      'service_z6pby1j',
      'template_9qep1fo',
      e.target,'user_w8UGktdue2sB3dmsdAGC2'
      ).then(res=>{
        console.log(res);
      }).catch(err=> console.log(err));
  }

  useEffect(() => {
    // Get state from public endpoint
    axios
      .post(
        process.env.REACT_APP_SUNSOFT_RESTSERVER_STATE_LIST,
        { companyCode: "SUNWST000", domain: "loanfast.com" },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        // Set the dropdown state list option.
        setStatesOptions(
          response.data.map((item, index) => (
            <option key={index} value={item.code}>
              {item.name}
            </option>
          ))
        );
      });

    /* Get client IP and geolocation(maxmind API) Task LRTV-3 */
    axios
      .get("https://api.ipify.org", { timeout: 2000 })
      .then((response) => {
        let userIP = response.data;

        if (response.data) {
          setClientIP(response.data);

          axios
            .post(
              process.env.REACT_APP_MAXMIND,
              { ip: clientIP || userIP },
              {
                headers: { "Content-Type": "application/json" },
              },
              { timeout: 5000 }
            )
            .then((response) => {
              if (
                response.data !== "IP Not Available" &&
                response.data !== "Invalid Country"
              ) {
                let zipcode = response.data.zipcode;
        
                if (zipcode) {
                  axios
                    .get(
                      process.env.REACT_APP_SUNSOFT_RESTSERVER_ZIPCODE +
                        zipcode,
                      { timeout: 5000 },
                      {
                        headers: { "Content-Type": "application/json" },
                      }
                    )
                    .then((response) => {
                      setPropAddressState(response.data.state);
                    });
                } 
              } 
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
      <div className=" bluebg">
         <h2 className="contacth2">Contact Us</h2>
          <div className="container border">
          <Form onSubmit={sendEmail}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label></Form.Label>
                <Form.Control type="text" placeholder="Your First Name" name="fname" />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label></Form.Label>
              <Form.Control type="text" placeholder="Your First Name"  name="lname"/>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Control type="emil" placeholder="Your Email" name="email" />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
              <Form.Control type="text" placeholder="Your Phone Number" name="phone" />
              </Form.Group>
            </Row>

          <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Control
                    value={propAddressState}
                    placeholder="Property State"
                    onChange={(e) => {
                      setPropAddressState(e.target.value);
                    }}
                    as="select"
                    custom
                  >
                    <option value="">Your State</option>
                    {statesOptions}
                  </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3"    >
          
            <Form.Control as="textarea" rows={3} placeholder="Your Message "  name="Message"/>
          </Form.Group>
          <div className="centercss">
          <input type="submit" value='Send' className="sendbtn"/>
          </div>
         
          </Form>
           
          </div>
 
          <div className="homecontact">
      <Link to="/">
         <Button color="inherit"  className="homebtn">Home</Button>
      </Link>
      </div>
    </div>
  );
}

export default Contact;
